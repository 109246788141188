// import Swiper from 'swiper';
import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
// configure Swiper to use modules
import $ from "jquery";
import sal from 'sal.js';
import axios from 'axios';
import { uaCheck } from "./modules/utils";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
uaCheck();

let entryY = 0;

const mq = (device) => {
  if (window.matchMedia('(max-width:768px)').matches && device === 'sp') {
    return true;
  }
  return window.matchMedia('(min-width:769px)').matches && device === 'pc';
};

const goToPageTop = (val) => {
  $(val).on('click', () => {
    $('html, body').animate({ scrollTop: 0 }, 500, 'swing');
    return false;
  });
};

const smoothScroll = (val) => {
  $(val).on('click', (e) => {
    const speed = 500;
    let adjust = 0;
    if (mq('pc')) {
      adjust = 130;
    } else {
      adjust = 100;
    }
    const href = $(e.currentTarget).attr('href');
    const target = $(href === '#' || href === '' ? 'html' : href);
    const position = target.offset().top - adjust;
    $('html, body').animate({ scrollTop: position }, speed, 'swing');
    return false;
  });
};

const recruitVoiceListSlider = () => {
  // 現在のURLを取得
  const url = window.location.href;
  const delimiter = "detail-";
  console.log(url);
  if (url.includes(delimiter)) {
    // "detail-"で分割して配列に格納
    const urlParts = url.split(delimiter).filter(Boolean);

    // urlParts[1]から数字を取得
    let num = urlParts[1].match(/\d+/);
    let nextNum = 0;
    const slideNum = document.querySelectorAll('.recruit-voice-slider-list li').length;

    if(slideNum === Number(num[0])){
      nextNum = 0;
    }else{
      // eslint-disable-next-line prefer-destructuring
      nextNum = Number(num[0]);
    }
    console.log(nextNum);

    // eslint-disable-next-line no-unused-expressions
    slideNum === Number(num) ? num = 1 : num;
    const recruitVoiceSlider = new Swiper('.recruit-voice-slider-list', {
      slidesPerView: 'auto',
      breakpoints: {
        768: {
          slidesPerView: 3,
        }
      },
      loop: true,
      spaceBetween: 80,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      centeredSlides: true,
      // num番目のスライドへ移動
      initialSlide: nextNum,

    });
} else {
  const swiper = new Swiper('.recruit-voice-slider-list', {
      slidesPerView: 'auto',
      breakpoints: {
        768: {
          slidesPerView: 3,
        }
      },
      loop: true,
      spaceBetween: 80,
      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      centeredSlides: true,

    });
}


}





// Menu UI Functions
const menuToggleFunc = () => {
  const mTrigger = document.getElementById("menu-trigger");
  mTrigger.addEventListener("click", () => {
    if ($('body').hasClass('menu-show')) {
      $('body').removeClass('menu-show');
    } else {
      $('body').addClass('menu-show');
    }
  });
}






const formCheck = () => {
  const kojincheck = document.getElementById("kojincheck");
  const submitBtn = document.getElementById("submitBtn");
  const kojincheckParent = kojincheck.parentElement;

  const checkBoxFunc = () => {
    const checkboxes = document.querySelectorAll('input[name="checkbox1[]"]');
    const checkboxLength = checkboxes.length;
    let checkedBoxNum = 0;

    kojincheck.disabled = true;
    kojincheckParent.style.opacity = 0.7;

    // オーダー用
    let orderCount = "";
    let hasCups = "";
    let cupCount = "";
    const hasCupValue = "要";
    const isOrderForm = document.getElementById("obecureOrder") !== null;

    if (isOrderForm) {
      orderCount = document.querySelectorAll(".order-count");
      hasCups = document.querySelectorAll(".has-cup");
      cupCount = document.querySelector(".cup-count");
      hasCups.forEach((hasCup, hasCupIndex) => {
        hasCup.addEventListener("click", (e) => {
          if (e.target.value === hasCupValue ) {
            cupCount.required = e.target.checked;
            cupCount.disabled = !e.target.checked;
          }else{
            cupCount.required = false;
            cupCount.disabled = true;
          }
        });
      });
    }

    checkboxes.forEach((checkbox, checkIndex) => {
      checkbox.addEventListener("click", (e) => {
        checkedBoxNum += e.target.checked ? 1 : -1;

        if (isOrderForm) {
          orderCount[checkIndex].required = e.target.checked;
          orderCount[checkIndex].disabled = !e.target.checked;
        }

        kojincheck.disabled = checkedBoxNum === 0;
        kojincheckParent.style.opacity = checkedBoxNum === 0 ? 0.7 : 1;
      });
    });
  };

  if (document.getElementById("hasCheckBoxRequired")) {
    checkBoxFunc();
  }

  kojincheck.addEventListener("change", () => {
    submitBtn.disabled = !kojincheck.checked;
  });

  // eslint-disable-next-line consistent-return
  submitBtn.addEventListener("click", (e) => {
    if (submitBtn.classList.contains("disabled")) {
      return false;
    }
    // 追加の処理があればここに記述
  });

};


window.addEventListener('DOMContentLoaded', (event) => {
  menuToggleFunc();
  sal();
  if (document.getElementById("formPage1")) {
    formCheck();
  }

  if (document.getElementById("jsSlider")) {
    recruitVoiceListSlider();
  }
});
